<template>
    <div>
        <headTitle/>
        <img class="mobile-example-headImg" src="../../assets/image/mobile/02-6IQVIA一站式多渠道客户营销管理平台.png"/>
        <div class="mobile-head-explain">
            <div class="mobile-head-explain-title" style="font-size:4rem">IQVIA一站式多渠道客户营销管理平台</div>
            <div class="mobile-head-explain-content">提供数字化技术创新理念</div>
        </div>
        <div class="mobile-index-card-3" style="margin-top:3.1rem" @click="jumpMobileRoute('/mobile-programme-efficiency')">
            <img src="../../assets/image/example/08-0销售效能管理.png"/>
            <div class="title1">销售效能管理</div>
            <div class="title2">面向医药和器械行业销售效能管理一站式数字化平台
            </div>
            <div class="title3">了解详情</div>
        </div>
        <div class="mobile-index-card-3" style="margin-top:2.2rem" @click="jumpMobileRoute('/mobile-programme-operate')">
            <img src="../../assets/image/example/08-0销售运营管理.png"/>
            <div class="title1">销售运营管理</div>
            <div class="title2">专为生命科学行业设计打造的销售运营管理解决方案
            </div>
            <div class="title3">了解详情</div>
        </div>
        <div class="mobile-index-card-3" style="margin-top:2.2rem" @click="jumpMobileRoute('/mobile-programme-science')">
            <img src="../../assets/image/example/08-0学术会议管理.png"/>
            <div class="title1">学术会议管理</div>
            <div class="title2">面向医药和器械行业销售效能管理一站式数字化平台
            </div>
            <div class="title3">了解详情</div>
        </div>
        <div class="mobile-index-card-3" style="margin-top:2.2rem;margin-bottom: 6rem;" @click="jumpMobileRoute('/mobile-programme-market')">
            <img src="../../assets/image/example/08-0市场准入与商务运营.png"/>
            <div class="title1">市场准入与商务运营</div>
            <div class="title2">面向医药和器械行业市场准入与商务运营管理
            </div>
            <div class="title3">了解详情</div>
        </div>
        <mobileFoot/>
    </div>
</template>

<script>
import headTitle from '../../components/mobile/head-title.vue'
import mobileFoot from '../../components/mobile/mobile-foot.vue'
import mobileCarousel from '../../components/mobile/mobile-carousel.vue'
import Swiper from "swiper";
export default {
    name: 'BizfocusIndexMobileIndex',
    components:{headTitle,mobileCarousel,mobileFoot},
    data() {
        return {
            box2ImageList:[
                {
                    index:0,
                    urlImg:require("../../assets/image/index/01-15GF整合数据分析系统.png"),
                    title:'5GF整合数据分析系统',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-5GF'
                },
                {
                    index:1,
                    urlImg:require("../../assets/image/index/01-1数字化产线.png"),
                    title:'数字化产线', //右转是0
                    content:'打造透明、高效、稳定的数字化生产管理平台',
                    route:'/selected-line'
                },
                {
                    index:2,
                    urlImg:require("../../assets/image/index/01-1市场渗透率分析.png"),
                    title:'市场渗透率分析',
                    content:'全球某化妆品巨头',
                    route:'/selected-marykay'
                },
                {
                    index:3,
                    urlImg:require("../../assets/image/index/01-1智能仓储.png"),
                    title:'智能仓储',
                    content:'布局原料赋码，助力品质生产',
                    route:'/selected-warehousing'
                },
                {
                    index:4,
                    urlImg:require("../../assets/image/index/01-1Dashboard数据实施.png"),
                    title:'Dashboard数据实施',
                    content:'全球某知名乳制品公司',
                    route:'/selected-dashboard'
                },
                {
                    index:5,
                    urlImg:require("../../assets/image/index/01-1商情诊断管理分析.png"),
                    title:'商情诊断管理分析',
                    content:'全球某知名日用消费品公司',
                    route:'/selected-business'
                },
                {
                    index:6,
                    urlImg:require("../../assets/image/index/01-1iDAS整合数据分析系统.png"),
                    title:'iDAS整合数据分析系统', //左转是0
                    content:'韩国某知名化妆品集团',
                    route:'/selected-iDAS'
                },

            ],
        };
    },

    mounted() {
    },

    methods: {
        jumpMobileRoute(route){
            this.$router.push(route)
            console.log(route)
        }
    },
};
</script>

<style lang="less" scoped>
.mobile-index-card-3{
    height: 68rem;
    width: 69rem;
    margin: auto;
    background: #F9F9F9;
    img{
        width: 63rem;
        height: auto;
        margin: 3.1rem 0 0 3.1rem;
    }
    .title1{
        font-size: 3.2rem;
        font-family: 'CN_Medium';
        font-weight: 500;
        color: #000000;
        line-height: 4.8rem;

        margin: 2rem 0 0 3.1rem;
    }
    .title2{
        width: 65rem;
        font-size: 2.8rem;
        height: 5.2rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #666666;
        line-height: 3rem;
        // margin: auto;
        margin: 1rem 0 0 3.1rem;
        // margin-top: 1rem;
    }
    .title3{
        font-size: 2.8rem;
        font-family: 'CN_Normal';
        font-weight: 400;
        color: #11A84F;
        line-height: 3rem;

        margin: 1.8rem 0 0 3.1rem;
    }
}
</style>